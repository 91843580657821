.footer {
  background-color: var(--red);
  color: white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Zmiana na flex-end */
  text-align: center;
}

.footer-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.footer-buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.footer-button {
  margin-right: 10px;
  color: white !important;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 0 !important;
}

.footer-button a {
  color: white;
}
.footer-text {
  font-size: 16px;
  margin-top: 5px;
}

@media (max-width: 768px) {
  .footer-button {
    margin-right: 0;
    font-size: 12px;
  }
  .footer-text {
    font-size: 11px;
    margin-top: 0;
  }
}
