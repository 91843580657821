body {
  margin: 0;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--red);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
