.container-admin {
  height: auto;
  min-height: 90vh;
  text-align: center;
}
.input-admin {
  width: 150px;
  margin-top: 200px;
}

.container-admin ul {
  list-style-type: disc;
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

h3 {
  font-family: "Poppins";
  padding: 5px;
}
.delivery-info li {
  text-align: center;
  width: 100%;
}
.container-admin li {
  display: inline-flex;
  padding: 5px;
  font-family: "Poppins";
}

.sinfo {
  background-color: rgb(255, 255, 255);
}

.sinfo li {
  display: flex;
  text-align: center;
}

.info {
  padding: 20px;
  margin: 20px 20px;
}
.infodiv {
  border: 2px solid black;
  justify-content: space-between;
}
.infodiv:nth-child(even) {
  background-color: rgb(255, 149, 154);
}

h2 {
  margin-bottom: 30px;
  font-family: "Poppins";
}

.fade {
  background-color: rgb(236, 246, 255);
}
.fade:nth-child(even) {
  background-color: rgb(255, 190, 193);
}

.container-admin {
  height: auto;
  min-height: 90vh;
  text-align: center;
}

.input-admin {
  width: 150px;
  margin-top: 20px; /* Adjusted for responsiveness */
}

h3 {
  font-family: "Poppins";
  padding: 5px;
}

.delivery-info li {
  text-align: center;
  width: 100%;
}

.container-admin li {
  display: inline-flex;
  padding: 5px;
  font-family: "Poppins";
}

.sinfo {
  background-color: rgb(255, 255, 255);
}

.sinfo li {
  display: flex;
  text-align: center;
}

.info {
  padding: 20px;
  margin: 20px 20px;
}

.infodiv {
  border: 2px solid black;
  margin-bottom: 10px;
  justify-content: space-between;
}

.infodiv:nth-child(even) {
  background-color: rgb(255, 149, 154);
}

h2 {
  margin-bottom: 30px;
  font-family: "Poppins";
}

.fade {
  background-color: rgb(236, 246, 255);
}

/* Display li elements beneath each other inside .fade */
.fade li {
  display: flex;
  margin-bottom: 5px;
}

.fade:nth-child(even) {
  background-color: rgb(255, 190, 193);
}

/* Media Query for smaller screens */
@media only screen and (max-width: 768px) {
  .input-admin {
    width: 100%; /* Take up full width */
    margin-top: 10px; /* Adjusted for responsiveness */
  }

  .info {
    margin: 10px; /* Adjusted for responsiveness */
  }

  .infodiv {
    margin-bottom: 5px; /* Adjusted for responsiveness */
  }

  .fade li {
    display: block; /* Display li elements beneath each other */
    margin-bottom: 5px;
  }
}
