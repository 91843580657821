.container {
  min-height: 85vh;
  height: auto;
  margin-bottom: 3vh;
}
.order-summary {
  max-width: 800px;
  margin: 0 auto;
    height: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Poppins";
  margin-bottom: 3vh;
}

.order-summary h2 {
  text-align: center;
  font-size: 36px;
  color: var(--red);
  margin-bottom: 20px;
}

.products {
  margin-bottom: 30px;
}

.total-amount {
  text-align: center;
  margin-bottom: 20px;
  color: var(--red);
}

.products h3 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 10px;
}

.products ul {
  list-style: none;
  padding: 0;
}

.products li {
  font-size: 14px;
  margin-bottom: 10px;
}

.delivery-info h3 {
  text-align: center;
  font-size: 22px;
  margin-bottom: 10px;
}

.delivery-info p {
  font-size: 14px;
  margin-bottom: 10px;
}

.confirm-text {
  font-size: 36px;
  font-family: "Poppins";
  color: var(--red);
  text-align: center;
  align-items: center;
}
.order-confirmation {
  text-align: center;
}
.confirm-text button {
  margin: auto;
}
/* Dodatkowe style dla responsywności */
@media (max-width: 768px) {
  .order-summary {
    max-width: 100%;
  }
  .container {
    height: auto;
    min-height: 90vh;
  }
}
