.container-error {
  text-align: center;
  height: 90vh;
  justify-content: center;
}

.error{
    font-size: 36px;
    font-family: "Poppins";
    color: var(--red);
    margin-top: 20px;
}

.err{
    text-decoration: none;
    color: white;
}
