* {
  margin: 0;
}
.container {
  width: 100%;
  height: 85vh;
  display: flex;
  overflow: hidden;
  margin: 0px 10px 0 10px;
}

.arrow {
  width: 50px;
  height: 50px;
  background-color: white;
  color: var(--red);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  z-index: 2;
}

.wrapper {
  height: 100%;
  display: flex;
  transition: 2s;
  width: 300vw;
}

.slide {
  display: flex;
  align-items: center;
  width: 100vw !important;
  height: 100vh;
}

.img-container {
  flex: 1;
  height: 100%;
}

.info-container {
  flex: 1;
  padding: 50px;
  text-align: center;
}

.title {
  font-size: 70px;
  font-weight: 500;
  letter-spacing: 5px;
  color: var(--red);
}

.button1 {
  padding: 10px;
  font-size: 20px;
  background-color: transparent;
  cursor: pointer;
}

.description {
  margin: 50px 0px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 3px;
}

.img {
  height: 80%;
  border: 2px solid var(--red);
}

@media (max-width: 768px) {
  .img-container {
    width: 100vw;
    height: 30vh;
    flex: none;
  }

  .arrow {
    background-color: transparent;
  }

  img {
    margin: auto;
    width: 95%;
    height: auto !important;
  }

  .slide {
    flex-direction: column;
  }

  .title {
    font-size: 36px;
    font-family: "Poppins";
  }
}
