.product-detail {
  display: flex;
  flex-direction: row;
  height: 88vh;
}

.product-image {
  flex: 2;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.product-image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  border: 2px solid var(--red);
}

.product-info {
  flex: 1;
  padding: 20px; /* Dodatkowy padding tylko po lewej stronie */
  background-color: #ffffff;
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.product-info h2 {
  color: var(--red);
  font-family: "Poppins";
  font-size: 42px;
  margin-bottom: 20px;
  letter-spacing: 2px;
}

.product-info p {
  font-family: "Poppins";
  font-size: 18px;
  margin-top: 20px;
}

.choise {
  font-size: 16px;
  font-family: "Poppins";
  margin-bottom: 5px;
}

.size-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Dodatkowy odstęp między label a select */
}

label {
  margin-bottom: 20px;
  margin-top: 20px;
  font-size: 26px;
  font-family: "Poppins";
}

.product-info form label {
  font-size: 16px;
  font-family: "Poppins";
  margin-bottom: 5px;
}

input {
  text-align: center;
  font-family: "Poppins";
  font-size: 16px;
  width: 50px;
  margin-bottom: 10px;
  border: 1px solid #000000;
  padding: 5px;
}

button {
  margin-top: 30px;
}

.product-description {
  padding: 40px;
  padding-top: 20px;
  font-family: "Poppins";
  font-size: 16px;
}

.text {
  width: 100%;
  padding: 10px;
  font-size: 18px;
  font-family: "Poppins";
  margin-bottom: 15px;
}
.big {
  font-weight: 700;
  cursor: pointer;
}

.big2 {
  font-weight: 700;
}
@media (max-width: 768px) {
  .product-select {
    width: 50%;
  }
  .product-detail {
    flex-direction: column; /* Zmiana na układ kolumnowy dla mniejszych ekranów */
    height: auto; /* Automatyczna wysokość dla układu kolumnowego */
  }

  .product-image {
    flex: 1; /* Rozciągnij obraz na całą szerokość */
    margin-left: 0; /* Usuń lewy margines */
    margin-bottom: 20px; /* Dodatkowy odstęp między obrazem a tekstem */
  }

  .product-info {
    flex: 1;
    margin-right: 0; /* Usuń prawy margines */
    margin-left: 0; /* Usuń lewy margines */
    padding: 10px; /* Zmniejsz padding */
  }

  .product-info h2 {
    font-size: 24px; /* Zmniejsz rozmiar czcionki nagłówka */
    margin-bottom: 10px; /* Zmniejsz odstęp między nagłówkiem a tekstem */
  }

  label {
    font-size: 18px; /* Zmniejsz rozmiar czcionki dla etykiet */
    margin-bottom: 10px; /* Zmniejsz odstęp między etykietami a kontrolkami */
    margin-top: 10px; /* Zmniejsz odstęp na górze etykiety */
  }

  input {
    width: 15%; /* Rozciągnij pola wejściowe na całą szerokość kontenera */
    margin-bottom: 10px; /* Zmniejsz odstęp między polami wejściowymi */
  }

  button {
    margin-top: 20px; /* Zmniejsz odstęp na górze przycisku */
  }
}
