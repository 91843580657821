.info-container2 {
  width: 100%;
  min-height: 90vh; /* Set a minimum height */
  align-items: center;
}

.info-details {
  flex: 1;
}

.header-info {
  text-align: center;
  font-weight: 700;
  font-size: 72px;
  font-family: "Poppins";
  color: var(--red);
  letter-spacing: 20px;
}
.info-text {
  text-align: center;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 3px;
  font-family: "Poppins";
}
.map-container {
  margin-top: 20px;
  text-align: center;
}

iframe {
  border: 3px solid var(--red) !important;
}

@media (max-width: 1300px) {
  .header-info {
    font-size: 32px;
  }

  .info-text {
    font-size: 16px;
  }

  .info-container2 {
    height: 120vh;
  }
}

@media (max-width: 1080px) {
  .info-container2 {
    height: 100vh; /* Adjust the height for 1080p screens */
  }
}

@media (max-width: 768px) {
  .map-container {
    display: none; /* Ukryj kontener z mapą */
  }
  .header-info {
    font-size: 26px;
    font-family: "Poppins";
  }

  .info-container2 {
    height: 85vh;
  }
  .info-text {
    font-size: 16px;
    font-family: "Poppins";
  }
}

@media (max-width: 576px) {
  .header {
    font-size: 28px;
  }

  .span {
    font-size: 18px;
    margin-bottom: 3vh;
  }
}
