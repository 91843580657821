.slider-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 20px auto;
  overflow: hidden;
}

.slider-wrapper {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.slider-slide {
  min-width: 100%;
  transition: transform 0.5s ease;
}

.arrow-left {
  left: 10px;
}

.arrow-right {
  right: 10px;
}
