.produkty-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.produkt {
  width: 30%;
  margin: 10px 0;
  padding: 20px;
  border: 2px solid #000000;
  text-align: center;
  font-family: "Poppins";
  font-weight: normal;
  transition: transform 0.3s ease, background-color 0.3s ease !important;
}

.produkt img {
  max-width: 100%;
  height: auto;
}

.produkt.odd {
  border: 2px solid var(--red);
}

a {
  text-decoration: none;
  color: #000;
}

.produkt:hover {
  transform: scale(1.05) !important;
  background-color: #fffbfb !important;
}

@media (max-width: 768px) {
 .produkt h2{
  font-size: 16px;
 }
 .produkt p{
  font-size: 14px;
 }
 .produkt{
    width: 40%;
    padding: 10px;
 }
}