/* Center the content of the page */
.sadmin_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

/* Form styles */
.sadmin_form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 40vw;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sadmin_variant {
  margin-bottom: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
}
h4 {
  display: flex;
}
.sadmin_variant:nth-child(even) {
  border: 10px solid #721c24;
}

.sadmin_variant:nth-child(odd) {
  border: 10px solid #ff9ba5;
}

.sadmin_input {
  display: flex;
  width: calc(100% - 30px) !important;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.sadmin_button {
  margin: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.sadmin_heading,
.sadmin_subheading {
  color: #000000;
  font-size: 36px;
  margin-top: 30px;
}

/* List styles */
.sadmin_product_list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 600px;
}

.sadmin_product_item {
  background-color: white;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60vw;
}

/* Message styles */
.sadmin_message {
  margin: 10px 0;
  padding: 10px;
  border-radius: 4px;
  width: calc(100% - 40px);
  max-width: 600px;
}

.sadmin_message_success {
  background-color: #d4edda;
  color: #155724;
}

.sadmin_message_error {
  background-color: #f8d7da;
  color: #721c24;
}

/* Variant styles */
.sadmin_variant {
  margin-top: 10px;
}

.sadmin_checkbox_label {
  display: flex;
  align-items: center;
}

.sadmin_checkbox {
  margin-right: 5px;
}

.sadmin_photo_entry {
  margin-top: 10px;
}
