.all {
  height: auto;
  min-height: 90vh;
}
.form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-row {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-group {
  margin-right: 20px;
  width: 50%;
  text-align: center;
}

.form-label {
  margin-top: 0;
  margin-bottom: 8px;
  font-weight: bold;
  display: block;
  font-size: 16px;
}

.form-input-shipping {
  width: 170px;
  padding: 12px;
  border: 1px solid #000000;
  border-radius: 4px;
  margin: 8px 0;
  font-size: 16px;
}

.header-s {
  margin-bottom: 30px;
}

.email {
  width: 250px !important;
}

@media (max-width: 768px) {
  .form-input-shipping {
    width: 30%;
  }
  .phone {
    width: 60%;
  }
  .email {
    width: 90% !important;
  }

  .form-label {
    font-size: 12px;
    height: 30px;
  }
  .form-container {
    height: auto;
  }
  .all {
    height: auto;
    min-height: 90vh;
  }
}
