body {
  overflow-x: hidden;
}

.container-contact {
  height: 90vh;
  width: 100vw;
  display: flex;
  text-align: center;
  flex-direction: column;
  overflow: hidden;
}
.header {
  padding-top: 20px;
  display: flex;
  font-family: "Poppins";
  color: var(--red);
  justify-content: center;
  font-size: 62px;
}

.span {
  font-family: "Poppins";
  font-size: 34px;
  margin-bottom: 5vh;
}

.form-text {
  font-family: "Poppins";
  font-size: 24px;
  color: var(--red);
}

input[type="text"],
input[type="email"],
select {
  width: 30%;
  padding: 12px 20px;
  margin: 8px 0;
  border: 1px solid #000000;
  border-radius: 4px;
}

textarea {
  margin: 8px 0;
  width: 50%;
  height: 175px;
  padding: 12px 20px;
  border: 1px solid #000000;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
}

button {
  background-color: var(--red);
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

button:hover {
  background-color: white;
  color: var(--red);
  transition: 0.5s;
  border: 2px solid var(--red);
}

@media (max-width: 768px) {
  .header {
    font-size: 36px;
    font-family: "Poppins";
  }

  .span {
    font-size: 24px;
    font-family: "Poppins";
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    width: 90%;
    box-sizing: border-box;
  }

  .custom {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .header {
    font-size: 28px;
  }

  .span {
    font-size: 18px;
    margin-bottom: 3vh;
  }

  .form-text {
    font-size: 14px;
  }

  input[type="text"],
  input[type="email"],
  select,
  textarea {
    padding: 10px;
    font-size: 14px;
  }

  button {
    padding: 12px;
    font-size: 14px;
    margin-bottom: 20px;
  }

  .container-contact {
    height: 90vh;
  }
}
