.navbar {
  height: 7vh;
  color: white;
  font-family: "Poppins";
  border-top: 2px solid var(--red);
  background-color: var(--red);
  margin-bottom: 10px;
  width: 100vw;
}

.navbar-wrapper {
  padding: 10px 20px;
  display: flex;
}

.navbar-logo {
  font-weight: 800;
  flex: 1;
  font-size: x-large;
  color: white;
  margin: auto;
}

.navbar-logo span {
  font-weight: 500;
}
.navbar-link {
  color: white;
}
.active {
  font-size: large;
  font-weight: bold;
}

.navbar-button {
  font-family: "Poppins";
  flex: 7;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: white;
}

/* Ikonka menu mobilnego */
.mobile-menu-icon {
  cursor: pointer;
  font-size: 24px;
  color: white;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 3;
  display: none;
}

/* Styl menu mobilnego */
.mobile-menu {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 60px;
  right: 20px;
  background-color: var(--red);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 10;
}

.mobile-menu a {
  padding: 12px;
  text-decoration: none;
  color: white;
  transition: background-color 0.3s;
}

.mobile-menu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Warunkowe wyświetlanie menu na urządzeniach mobilnych */
@media (max-width: 768px) {
  .navbar-button {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
  }

  .navbar.mobile-menu-open .mobile-menu-icon {
    display: block;
  }

  .navbar.mobile-menu-open .mobile-menu {
    display: flex;
  }
}
