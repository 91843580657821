.cart-container {
  min-height: 90vh;
  height: auto;
}
.cart {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  height: auto;
}

.cart h2 {
  font-size: 36px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--red);
  font-family: "Poppins";
}

.cart ul {
  list-style: none;
  padding: 0;
  font-size: 18px;
  font-family: "Poppins";
}

.cart li {
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 5px;
  background-color: #fff;
}

.cart li div {
  margin-top: 10px;
}

.cart button {
  font-family: "Poppins";
  margin: 5px 20px;
  padding: 5px 10px;
  background-color: var(--red);
  color: #fff !important;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  opacity: 0.5;
  transition: 0.5s;
}

.cart button:hover {
  opacity: 1;
  scale: 1.05;
}
.important {
  opacity: 1 !important;
}

.cart p {
  text-align: right;
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
  font-family: "Poppins";
}

.cart p span {
  color: var(--red);
}

span {
  color: var(--red);
}

@media (max-width: 768px) {
  .cart {
    padding: 10px; /* Dostosuj padding na urządzeniach mobilnych */
    width: 80%;
  }

  .cart h2 {
    font-size: 24px; /* Dostosuj rozmiar czcionki dla nagłówka na urządzeniach mobilnych */
  }

  .cart li {
    margin-bottom: 10px;
    font-size: 16px; /* Dostosuj rozmiar czcionki dla elementów listy na urządzeniach mobilnych */
  }

  .cart button {
    margin: 5px 10px;
    padding: 5px 8px;
    font-size: 14px; /* Dostosuj rozmiar czcionki dla przycisku na urządzeniach mobilnych */
  }

  .cart p {
    font-size: 16px; /* Dostosuj rozmiar czcionki dla tekstu na urządzeniach mobilnych */
  }
  .cart-container {
    height: auto;
    min-height: 90vh;
  }
}
